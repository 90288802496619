<template>
  <content-layout
    :loading="loading"
    :breadcrumbs-items="breadcrumbsItems"
    :fluid="false"
  >
    <template #toolbar-actions>
      <div class="d-flex align-center" style="gap: 15px">
        <cz-menu-button
          v-if="canCreateChildSubmissions"
          :button-icon-src="mdiPlusCircleOutline"
          color="info"
          :button-icon-size="22"
          :button-title="$t('vendor.orderSubmissions.createChildSubmission')"
          clickable
          :menu-items="submissionDocTypeToMenuItems || []"
          @action:clicked="onCreateChildSubmissionClicked"
          with-paddings
        />
        <v-menu offset-y :min-width="800" style="z-index: 160">
          <template v-slot:activator="{ on, attrs }">
            <cz-button
              title="סטטוס הגשה"
              outlined
              v-bind="attrs"
              v-on="on"
              :icon-src="mdiInformationOutline"
            />
          </template>
          <submission-workflow-stepper
            v-if="submission"
            :submission="submission"
          />
        </v-menu>
        <v-menu
          offset-y
          :nudge-bottom="10"
          :min-width="180"
          style="z-index: 160"
        >
          <template v-slot:activator="{ on, attrs }">
            <cz-button
              :title="$t('common.moreActions')"
              outlined
              :icon-src="mdiDotsHorizontalCircleOutline"
              v-bind="attrs"
              v-on="on"
            /> </template
          >ֿ
          <v-list>
            <v-list-item
              v-clipboard="submissionLink"
              @click="onGrabLinkClicked"
            >
              <v-list-item-icon class="ml-2"
                ><cz-icon :src="mdiContentCopy"
              /></v-list-item-icon>
              <v-list-item-title>{{
                $t('vendor.orderSubmissions.grabSubmissionLink')
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onShowHistory">
              <v-list-item-icon class="ml-2"
                ><cz-icon :src="mdiHistory"
              /></v-list-item-icon>
              <v-list-item-title>{{
                $t('customer.vendorSubmissions.submissionHistory')
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="
                (!loading && !saving && !sending && !readOnlyMode) ||
                (isOrderlessSubmission && decisionMode)
              "
              @click="onCancelSubmission"
            >
              <v-list-item-icon class="ml-2"
                ><cz-icon :src="mdiCloseCircleOutline"
              /></v-list-item-icon>
              <v-list-item-title>{{
                $t('vendor.orders.cancelSubmission')
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <v-card
      flat
      outlined
      rounded="lg"
      style="position: sticky; top: 110px; z-index: 101"
      class="mb-3"
    >
      <v-stepper
        flat
        v-model="currentSubmissionStep"
        v-if="submissionSteps.length > 1"
      >
        <v-stepper-header>
          <template v-for="(step, index) in submissionSteps">
            <v-stepper-step :key="step.id" :step="index + 1" color="primary">
              {{ step.text }}</v-stepper-step
            >
            <v-divider
              :key="`${step.id}_divider`"
              v-if="index < submissionSteps.length - 1"
            />
          </template>
        </v-stepper-header>
      </v-stepper>
      <v-tabs v-model="tab" v-else-if="submissionTabs.length > 0" color="info">
        <v-tab v-for="item of submissionTabs" :key="item.id">
          <cz-icon :src="item.icon" class="ml-2" v-if="item.icon" />
          {{ item.text }}
        </v-tab>
      </v-tabs>
    </v-card>

    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
      tag="div"
      v-if="submission"
    >
      <v-container
        class="pt-0 px-0"
        fluid
        style="padding-bottom: 80px"
        v-if="submission"
      >
        <v-row v-show="currentSubmissionStepId === 'submission-details'">
          <v-col :lg="7">
            <v-card flat :loading="loading" rounded="lg" :outlined="true">
              <v-card-text>
                <submission-form
                  v-model="submission"
                  :read-only-mode="readOnlyMode"
                  :is-vat-exempt="isVatExempt"
                  :loading="saving"
                  :vendor="orderlessVendor"
                  @show-order="onShowFullOrder"
                  :system-tags="systemTags"
                  @nav-to-parent-submission="navToParentSubmission"
                  @on-vendor-change="onOrderlessVendorChange"
                  :additional-documents.sync="additionalDocuments"
                  @on-save="saveSubmission"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col :lg="5">
            <v-card flat rounded="lg" outlined>
              <v-card-text class="pa-1">
                <submission-document
                  ref="submissionDocComponent"
                  v-if="submission"
                  :submission="submission"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card
          flat
          rounded="lg"
          v-show="currentSubmissionStepId === 'submission-items'"
        >
          <submission-items
            v-if="submission"
            v-model="submission"
            :vendor-vat="vendorVat"
            :readonly="readOnlyMode"
          />
        </v-card>

        <template v-if="!submission.relatedOrderId">
          <orderless-submission-approval
            v-show="currentSubmissionStepId === 'submission-approval-workflow'"
            v-model="submission"
            :account-workflows="accountWorkflows"
            :current-user="currentUser"
            :approvers="approvers"
            :readonly="readOnlyMode"
          />
        </template>

        <document-notes
          v-if="currentSubmissionStepId === 'submission-notes'"
          :items="submission.notes"
          :current-user="currentUser"
          @on-add="onAddNote"
          @on-delete="onDeleteNote"
        />

        <template v-if="currentSubmissionStepId === 'send'">
          <submission-summary
            v-if="submission"
            :can-send-submission="
              !invalid && !hasNegativeQuantityInSubmissionItems
            "
            :loading="sending"
            :submission="submission"
            @send="sendSubmission"
          />
        </template>
      </v-container>
      <v-footer
        fixed
        class="d-flex justify-space-between elevation-4"
        style="column-gap: 10px"
        color="white"
        :height="80"
      >
        <cz-button
          v-if="!decisionMode"
          v-visible="currentSubmissionStepId !== 'send' && !readOnlyMode"
          :title="$t('common.saveDraft')"
          :disabled="loading || sending || !formChanged"
          :icon-src="mdiContentSave"
          :loading="saving"
          @click="saveSubmission"
        />

        <div class="d-flex" style="column-gap: 15px" v-if="decisionMode">
          <cz-button
            :title="approveButtonTitle"
            dark
            large
            rounded
            color="positive"
            :icon-src="mdiCheckAll"
            @click="onApprove"
          />
        </div>

        <div
          class="d-flex justify-center"
          style="gap: 10px"
          v-if="submissionSteps.length > 1"
        >
          <cz-button
            :title="$t('common.prev')"
            :icon-size="20"
            :icon-src="mdiArrowRight"
            @click="currentSubmissionStep--"
            :disabled="currentSubmissionStep === 1"
            outlined
            :width="100"
          />
          <v-divider vertical class="my-1" />
          <cz-button
            :title="$t('common.next')"
            outlined
            :width="100"
            :icon-src="mdiArrowLeft"
            :disabled="currentSubmissionStep === submissionSteps.length"
            @click="currentSubmissionStep++"
            :icon-size="20"
            icon-location="append"
          />
        </div>
        <div
          class="d-flex align-center"
          style="gap: 15px"
          v-if="decisionMode && !isOrderlessSubmission"
        >
          <cz-button
            :title="
              $t('customer.vendorSubmissions.decisionActionButtons.return')
            "
            dark
            outlined
            rounded
            :icon-src="mdiUndoVariant"
            @click="onReturn"
          />
          <v-divider class="my-2" vertical />
          <cz-button
            :title="
              $t('customer.vendorSubmissions.decisionActionButtons.reject')
            "
            dark
            rounded
            :icon-src="mdiCloseCircleOutline"
            outlined
            color="negative"
            @click="onReject"
          />
        </div>
        <span v-else style="width: 150px" />
      </v-footer>
    </validation-observer>
    <submission-decision-dialog
      v-if="submissionDecisionDialog.show"
      v-model="submissionDecisionDialog.show"
      v-bind="submissionDecisionDialog"
      @close="submissionDecisionDialog.show = false"
      @on-submit="onSubmitSubmissionDecision"
    />

    <cz-prompt-dialog
      v-if="cancelSubmissionDialog.show"
      v-model="cancelSubmissionDialog.show"
      v-bind="cancelSubmissionDialog"
      @cancel="cancelSubmissionDialog.show = false"
      @done="cancelSubmission"
    />

    <cz-prompt-dialog
      v-if="promptDialog.show"
      v-model="promptDialog.show"
      v-bind="promptDialog"
      hide-cancel-button
      @done="promptDialog.show = false"
    />

    <submission-history-dialog
      v-if="submissionHistoryDialog.show"
      v-model="submissionHistoryDialog.show"
      v-bind="submissionHistoryDialog"
      @close="submissionHistoryDialog.show = false"
    />

    <new-submission-dialog
      v-if="createSubmissionDialog.show"
      v-model="createSubmissionDialog.show"
      v-bind="createSubmissionDialog"
      @continue="uploadOrderSubmissionForParentSubmission"
      @close="createSubmissionDialog.show = false"
    />
  </content-layout>
</template>

<script>
import {
  getOrderSubmission,
  deleteOrderSubmission,
  updateOrderSubmission,
  createOrderSubmissionDecision,
  uploadFiles,
  createOrderSubmissionDocument,
  getOrderSubmissionHistory,
  createOrderSubmission,
  getUserRoles,
  getVendor,
  createOrderSubmissionNote,
  deleteOrderSubmissionNote
} from '@/core/api';
import { mapFields } from 'vuex-map-fields';
import { CzButton, CzPromptDialog, CzIcon, CzMenuButton } from '@/components';
import { formatDateTimeToDisplay } from '@/shared/services/dates.service';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';
import { mapActions } from 'vuex';
import {
  mdiCheck,
  mdiInformation,
  mdiInformationOutline,
  mdiEye,
  mdiPlus,
  mdiPlusCircleOutline,
  mdiEyeOff,
  mdiDotsHorizontalCircleOutline,
  mdiFileDocumentOutline,
  mdiDelete,
  mdiDeleteOutline,
  mdiSendCheck,
  mdiCloseCircleOutline,
  mdiHistory,
  mdiContentSave,
  mdiContentCopy,
  mdiDownload,
  mdiArrowRight,
  mdiArrowLeft,
  mdiCheckAll,
  mdiUndoVariant,
  mdiInvoiceListOutline,
  mdiShapeOutline,
  mdiNoteMultipleOutline
} from '@mdi/js';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import OrderSubmissionStatus from '@/shared/types/OrderSubmissionStatus';
import { DecisionType } from '@/domain/customer/components/SubmissionDecisionDialog.vue';
import { typesMixin } from '@/shared/mixins';
import { format, parse } from 'date-fns';
import currencyTypes from '@/shared/types/CurrencyTypes';
import {
  userHasOneOfRolesWithName,
  userHasRoleWithName
} from '@/shared/services/rbac/rbac.service';
import RoleType from '@/shared/types/RoleType';
import { BusinessTypeVatAmount } from '@/shared/types/BusinessType';
import { VAT_PERCENT } from '@/shared/constants';
import SubmissionDocType from '@/shared/types/SubmissionDocType';
import { submissionDocTypes } from '../constants';
import { DOCUMENT_PREDICTION_TIMEOUT } from '@/domain/shared/constants';
import { extractRolesFromWorkflow } from '@/shared/services/workflow.service';
import groupBy from 'lodash.groupby';
import DocumentNotes from '@/domain/customer/components/DocumentNotes.vue';
import SubmissionDocumentStatus from '@/shared/types/SubmissionDocumentStatus';

export default {
  name: 'SubmissionPage',
  components: {
    CzPromptDialog,
    CzMenuButton,
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    NewSubmissionDialog: () =>
      import('@/domain/customer/components/NewOrderDialog.vue'),
    SubmissionDecisionDialog: () =>
      import('@/domain/customer/components/SubmissionDecisionDialog.vue'),
    SubmissionHistoryDialog: () =>
      import('../components/SubmissionHistoryDialog.vue'),
    SubmissionForm: () => import('../components/SubmissionForm.vue'),
    SubmissionItems: () => import('../components/SubmissionItems.vue'),
    OrderlessSubmissionApproval: () =>
      import('@/domain/customer/components/OrderlessSubmissionApproval.vue'),
    SubmissionWorkflowStepper: () =>
      import('../components/SubmissionWorkflowStepper.vue'),
    SubmissionDocument: () => import('../components/SubmissionDocument.vue'),
    SubmissionSummary: () => import('../components/SubmissionSummary.vue'),
    DocumentNotes,
    CzButton,
    CzIcon
  },
  props: {
    submissionId: {
      type: String,
      required: true
    },
    predicted: {
      type: Boolean
    },
    type: {
      type: String
    }
  },
  mixins: [typesMixin],
  created() {
    this.loadSubmission();
  },
  computed: {
    ...mapFields('vendor', ['vendor']),
    ...mapFields('auth', [
      'accountId',
      'account',
      'currentUser',
      'account.systemTags'
    ]),
    ...mapFields('customer', ['accountWorkflows']),
    /**
     * Decision mode will be applied only for authorizer/purchaser and with the
     * the relevant status of pending autorizer/purchaser
     */
    decisionMode() {
      return (
        this.userIsApprover &&
        this.submission?.status === OrderSubmissionStatus.PENDING_APPROVAL
      );
    },
    isRootSubmission() {
      return !this.submission?.parentSubmissionId;
    },
    currentVendor() {
      return this.submission?.relatedOrder?.vendor || this.vendor;
    },
    userIsApprover() {
      return this.submission?.approverUserId === this.currentUser?._id;
    },
    isOrderlessSubmission() {
      return this.submission?.source === 'orderless';
    },
    readOnlyMode() {
      if (!this.submission) {
        return true;
      }

      // only vendors can edit submissions
      if (
        !this.isOrderlessSubmission &&
        !userHasRoleWithName(this.currentUser, RoleType.VENDOR_ADMIN)
      ) {
        return true;
      }

      if (
        this.isOrderlessSubmission &&
        !userHasRoleWithName(this.currentUser, RoleType.CUSTOMER_PURCHASER)
      ) {
        return true;
      }

      return ![
        OrderSubmissionStatus.NEW,
        OrderSubmissionStatus.NEW_PREDICTED,
        OrderSubmissionStatus.RETURNED
      ].includes(this.submission.status);
    },
    userIsAuthorizer() {
      return userHasRoleWithName(
        this.currentUser,
        RoleType.CUSTOMER_AUTHORIZER
      );
    },

    isVatExempt() {
      return BusinessTypeVatAmount[this.currentVendor?.businessType] === 0;
    },
    vendorVat() {
      const val = BusinessTypeVatAmount[this.currentVendor?.businessType];
      return val !== undefined ? val : VAT_PERCENT;
    },
    userIsPurchaser() {
      return userHasRoleWithName(this.currentUser, RoleType.CUSTOMER_PURCHASER);
    },
    userIsObserver() {
      return userHasRoleWithName(this.currentUser, RoleType.CUSTOMER_OBSERVER);
    },
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: this.isVendorHomeRoute()
              ? 'vendor-home'
              : this.isCustomerRoute()
              ? 'customer'
              : 'home',
            params: {
              id: this.isVendorHomeRoute() ? this.currentVendor?._id : undefined
            }
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text:
            this.type === 'orderless'
              ? this.$t('customer.manageInvoicesTitle')
              : this.$t('vendor.orderSubmissions.submissionsTitle'),
          disabled: false,
          exact: true,
          to: {
            name: this.isVendorHomeRoute()
              ? 'vendor-submissions'
              : this.isCustomerRoute()
              ? this.type === 'orderless'
                ? 'customer-submissions-orderless'
                : 'customer-submissions'
              : 'home'
          }
        },
        {
          text: this.$t('vendor.orderSubmissions.submissionTitle', [
            this.submission ? this.submission?.documentNumber : '...'
          ]),
          disabled: true,
          exact: true
        }
      ];
    },
    formChanged() {
      const changed =
        !isEqual(this.submission, this.submissionCopy) ||
        this.additionalDocuments.length > 0;
      return changed;
    },
    orderNumber() {
      if (this.isOrderlessSubmission) {
        return this.$t('customer.orderlessSubmissions.orderlessSubmission');
      } else {
        return this.submission?.relatedOrder?.orderNumber;
      }
    },
    isManualOrder() {
      return this.submission?.relatedOrder?.creationType === 'manual';
    },
    docName() {
      if (this.documentType === 0) {
        return this.submission?.document?.name;
      } else if (this.documentType === 1) {
        return this.submission.relatedOrder?.orderDocument?.name;
      } else {
        return null;
      }
    },
    approveButtonTitle() {
      return this.$t(
        'customer.vendorSubmissions.decisionActionButtons.approve'
      );
    },
    currentSubmissionStepId() {
      return (
        this.submissionSteps[this.currentSubmissionStep - 1]?.id ||
        this.submissionTabs[this.tab]?.id
      );
    },
    submissionTabs() {
      const tabs = [
        {
          text: this.$t('vendor.orders.orderSubmissionDetailsForm'),
          id: 'submission-details',
          icon: mdiInformationOutline
        }
      ];

      if (
        this.submission?.relatedOrder?.documentType === 'order-items' &&
        this.submission?.documentType !== SubmissionDocType.RECEIPT
      ) {
        tabs.push({
          id: 'submission-items',
          text: this.$t('vendor.orders.submissionItems'),
          icon: mdiInvoiceListOutline
        });
      }

      // submission contains workflow for orderless submissions
      if (this.isOrderlessSubmission && !this.submission?.relatedOrderId) {
        tabs.push({
          id: 'submission-approval-workflow',
          text: this.$t('common.workflowApproval'),
          icon: mdiShapeOutline
        });
      }

      if (
        userHasOneOfRolesWithName(this.currentUser, [
          RoleType.CUSTOMER_PURCHASER,
          RoleType.CUSTOMER_ACCOUNT_MANAGER,
          RoleType.CUSTOMER_AUTHORIZER
        ])
      ) {
        tabs.push({
          text: this.$t('customer.orders.orderNotes'),
          id: 'submission-notes',
          icon: mdiNoteMultipleOutline
        });
      }

      return tabs;
    },
    submissionHasOrderWithItems() {
      return (
        this.submission?.relatedOrder?.documentType === 'order-items' ||
        this.submission?.parentSubmission?.relatedOrder?.documentType ===
          'order-items'
      );
    },
    submissionSteps() {
      const steps = [];

      if (
        (!this.isVendorHomeRoute() && !this.isOrderlessSubmission) ||
        this.readOnlyMode
      ) {
        return steps;
      }

      steps.push({
        id: 'submission-details',
        text: this.$t('vendor.orders.orderSubmissionDetailsForm')
      });

      if (this.submissionHasOrderWithItems) {
        if (
          !this.submission.parentSubmissionId ||
          (this.submission.parentSubmissionId &&
            this.submission?.documentType === SubmissionDocType.CREDIT_INVOICE)
        ) {
          steps.push({
            id: 'submission-items',
            text: this.$t('vendor.orders.submissionItems')
          });
        }
      }

      // submission contains workflow for orderless submissions
      if (
        this.isOrderlessSubmission &&
        !this.submission?.relatedOrderId &&
        !this.submission?.parentSubmissionId
      ) {
        steps.push({
          id: 'submission-approval-workflow',
          text: this.$t('common.workflowApproval')
        });
      }

      if (!this.readOnlyMode) {
        steps.push({
          id: 'send',
          text: this.$t('vendor.orders.submissionSummaryAndSend')
        });
      }

      return steps;
    },
    submissionLink() {
      return this.submission?.shortUrl || window.location.href;
    },
    hasNegativeQuantityInSubmissionItems() {
      if (this.submission?.submissionItems?.length) {
        return (
          this.submission.submissionItems.findIndex(
            (item) => item.quantity < 0
          ) !== -1
        );
      } else {
        return false;
      }
    },
    canCreateChildSubmissions() {
      return (
        this.isVendorHomeRoute() &&
        this.submission?.status === OrderSubmissionStatus.APPROVED &&
        this.isRootSubmission &&
        this.submissionDocTypeToMenuItems?.length
      );
    },
    submissionDocTypeToMenuItems() {
      if (!this.submission) {
        return [];
      }
      const menuItems = submissionDocTypes(
        this,
        this.vendor.vatExempt,
        this.submission.documentType,
        this.submission.documentStatus
      ).map((item) => {
        return {
          id: item.value,
          title: item.text,
          icon: item.icon
        };
      });

      return menuItems;
    }
  },
  data() {
    return {
      mdiPlusCircleOutline,
      mdiUndoVariant,
      mdiCheckAll,
      mdiInformationOutline,
      mdiInvoiceListOutline,
      mdiDotsHorizontalCircleOutline,
      mdiCloseCircleOutline,
      mdiArrowRight,
      mdiArrowLeft,
      mdiDownload,
      mdiContentCopy,
      mdiPlus,
      mdiDelete,
      mdiDeleteOutline,
      mdiCheck,
      mdiInformation,
      mdiEye,
      mdiEyeOff,
      mdiSendCheck,
      mdiHistory,
      mdiContentSave,
      mdiFileDocumentOutline,
      currencyTypes,
      documentType: 0,
      tab: 0,
      currentSubmissionStep: 1,
      submission: null,
      submissionCopy: null,
      additionalDocuments: [],
      loading: false,
      sending: false,
      saving: false,
      showDocs: false,
      issueDate: null,
      submissionDecisionDialog: {
        loading: false,
        show: false,
        decisionType: undefined,
        documentType: undefined,
        objectType: 'order-submission'
      },
      createSubmissionDialog: {
        show: false,
        title: '',
        inputLabel: null,
        parentSubmission: null,
        documentType: null,
        loadingTitle: null,
        loading: false,
        uploadProgress: 0
      },
      loadingSubmissionHistory: false,
      cancelSubmissionDialog: {
        show: false,
        title: this.$t('vendor.orderSubmissions.cancelSubmissionTitle'),
        message: this.$t('vendor.orderSubmissions.cancelSubmissionMessage'),
        doneButtonTitle: this.$t(
          'vendor.orderSubmissions.cancelSubmissionActionTitle'
        ),
        loading: false
      },
      promptDialog: {
        show: false,
        title: this.$t('common.unableToSendSubmission'),
        message: '',
        state: 'error',
        doneButtonTitle: this.$t('common.close')
      },
      submissionHistoryDialog: {
        show: false,
        loading: false,
        items: []
      },
      loadRetry: 0,
      approvers: null,
      orderlessVendor: null
    };
  },
  methods: {
    ...mapActions('ui', [
      'showSuccessToastMessage',
      'showErrorToastMessage',
      'showErrorPrompt'
    ]),
    ...mapActions('customer', ['loadAccountWorkflows']),
    formatAmountWithCurrency,
    formatDateTimeToDisplay,
    async loadSubmission() {
      try {
        this.loading = true;
        const $populate = ['workflowSteps', 'vendor'];
        if (this.isCustomerRoute()) {
          $populate.push('notes');
        }
        const submission = await getOrderSubmission(this.submissionId, {
          accountId: this.isCustomerRoute() ? undefined : this.accountId,
          $populate
        });
        if (
          this.predicted &&
          submission?.status !== OrderSubmissionStatus.NEW_PREDICTED &&
          this.loadRetry < 2
        ) {
          this.loadSubmission();
          this.loadRetry++;
          return;
        }

        if (submission.issueDate) {
          submission.issueDate = format(
            new Date(submission.issueDate),
            'yyyy-MM-dd'
          );
        }

        this.submission = submission;
        this.submissionCopy = structuredClone(submission);
        if (submission?.vendor) {
          this.orderlessVendor = structuredClone(submission.vendor);
        }

        if (
          this.isOrderlessSubmission &&
          this.type !== 'orderless' &&
          !this.readOnlyMode
        ) {
          this.$router.push({
            name: 'customer-submission',
            params: {
              id: this.id,
              type: 'orderless'
            }
          });
        }

        if (this.isOrderlessSubmission) {
          await this.loadAccountWorkflows(this.accountId);
          // this.loadOrderlessVendor();
          this.loadSubmissionApprovals();
        }
      } catch (error) {
        if (error.code === 404) {
          this.showErrorToastMessage(error.message);

          if (this.isVendorHomeRoute()) {
            this.$router.replace({
              name: 'vendor-submissions'
            });
          } else if (this.isCustomerRoute()) {
            this.$router.replace({
              name: 'customer-submissions'
            });
          }
        }
      } finally {
        this.loading = false;
      }
    },
    async onOrderlessVendorChange() {
      try {
        this.orderlessVendor = await getVendor(this.submission?.vendorId);
      } catch (error) {
        // vendor not found
        if (error.code === 404) {
          this.submission.vendorName = '';
          this.orderlessVendor = null;
        }
      }
    },
    async loadSubmissionApprovals() {
      const roles = extractRolesFromWorkflow(this.accountWorkflows);
      const { data: userRoles } = await getUserRoles({
        accountId: this.accountId,
        role: { $in: roles },
        $populate: ['user']
      });

      this.approvers = groupBy(userRoles, 'role');
    },
    onAdditionalDocumentChange(files, index) {
      this.additionalDocuments[index].file = files[0];
    },
    onAddDocument() {
      // add empty submission document to list
      this.additionalDocuments.push({
        type: '',
        file: null
      });
    },
    onRemoveDocument(index) {
      this.additionalDocuments.splice(index, 1);
    },
    viewDocument(item) {
      window.open(item.file.url, '_blank');
    },
    onCancelSubmission() {
      this.cancelSubmissionDialog.title = this.$t(
        'vendor.orderSubmissions.cancelSubmissionTitle',
        [this.submission.documentNumber]
      );
      this.cancelSubmissionDialog.show = true;
    },
    async cancelSubmission() {
      try {
        this.cancelSubmissionDialog.loading = true;

        await deleteOrderSubmission(this.submission._id, {
          accountId: this.accountId
        });

        this.cancelSubmissionDialog.show = false;
        this.showSuccessToastMessage(
          this.$t('vendor.orderSubmissions.submissionCancelledSuccesMessage')
        );
        this.navToSubmissions();
      } finally {
        this.cancelSubmissionDialog.loading = false;
      }
    },
    async saveSubmission() {
      try {
        const _submission = cloneDeep(this.submission);
        this.saving = true;
        const submissionDocuments = await this.createSubmissionDocuments(
          this.additionalDocuments,
          _submission
        );
        const submissionDocumentIds =
          submissionDocuments?.map((item) => item._id) || [];

        delete _submission.additionalDocumentsIds;
        if (_submission.issueDate) {
          _submission.issueDate = parse(
            _submission.issueDate,
            'yyyy-MM-dd',
            new Date()
          );
        }
        delete _submission.relatedOrder;
        delete _submission.parentSubmission;
        delete _submission.document;
        delete _submission.customer;
        delete _submission.additionalDocuments;
        delete _submission.vendor;

        if (!_submission.parentSubmissionId) {
          _submission.$unset = { parentSubmissionId: '' };
          delete _submission.parentSubmissionId;
        }

        const submission = await updateOrderSubmission(this.submission._id, {
          ..._submission,
          $push: { additionalDocumentsIds: submissionDocumentIds },
          note: ''
        });

        if (submission.issueDate) {
          submission.issueDate = format(
            new Date(submission.issueDate),
            'yyyy-MM-dd'
          );
        }

        this.submission = cloneDeep(submission);

        this.submissionCopy = cloneDeep(this.submission);
        this.additionalDocuments = [];

        this.showSuccessToastMessage(
          this.$t('vendor.orderSubmissions.submissionSavedSuccesMessage')
        );
      } catch (error) {
        this.showErrorPrompt(error.message);
      } finally {
        this.saving = false;
      }
    },
    navToSubmissions() {
      if (this.isCustomerRoute()) {
        this.$router.replace({
          name:
            this.type === 'orderless'
              ? 'customer-submissions-orderless'
              : 'customer-submissions'
        });
      } else if (this.isVendorHomeRoute()) {
        this.$router.replace({
          name: 'vendor-submissions'
        });
      }
    },
    navToParentSubmission() {
      if (this.isVendorHomeRoute()) {
        this.$router.push({
          name: 'vendor-submission',
          params: {
            submissionId: this.submission?.parentSubmissionId
          }
        });
      } else {
        this.$router.push({
          name: 'customer-submission',
          params: {
            submissionId: this.submission?.parentSubmissionId
          }
        });
      }
    },
    async sendSubmission() {
      try {
        if (!this.validateSubmissionBeforeSave()) {
          return;
        }

        this.sending = true;
        const _submission = cloneDeep(this.submission);
        const submissionDocuments = await this.createSubmissionDocuments(
          this.additionalDocuments,
          _submission
        );
        const submissionDocumentIds =
          submissionDocuments?.map((item) => item._id) || [];

        delete _submission.additionalDocumentsIds;

        if (_submission.issueDate) {
          _submission.issueDate = parse(
            _submission.issueDate,
            'yyyy-MM-dd',
            new Date()
          );
        }

        delete _submission.relatedOrder;
        delete _submission.parentSubmission;
        delete _submission.document;
        delete _submission.customer;
        delete _submission.additionalDocuments;
        delete _submission.vendor;
        await updateOrderSubmission(this.submission._id, {
          $push: { additionalDocumentsIds: submissionDocumentIds },
          ..._submission,
          note: '',
          status: 'pending_approval'
        });
        this.showSuccessToastMessage(
          this.$t('vendor.orderSubmissions.submissionSentSuccesMessage')
        );

        this.navToSubmissions();
      } catch (error) {
        this.showErrorPrompt(error.message);
        // this.showErrorToastMessage(error.message);
      } finally {
        this.sending = false;
      }
    },

    validateSubmissionBeforeSave() {
      // do not perform client side validations for submissions with no orders
      if (this.isOrderlessSubmission) {
        return true;
      }

      // check if total amount exceed order amount
      // for submissions with document type
      if (
        this.isRootSubmission &&
        [
          SubmissionDocType.INVOICE,
          SubmissionDocType.INVOICE_RECEIPT,
          SubmissionDocType.TRANSACTION_ACCOUNT
        ].includes(this.submission?.documentType)
      ) {
        const amountSub =
          parseFloat(this.submission.relatedOrder.amountLeft) -
          parseFloat(this.submission.totalAmount);

        if (amountSub < -1) {
          this.promptDialog.message = this.$t(
            'vendor.orderSubmissions.totalAmountExceed',
            [
              formatAmountWithCurrency(this.submission.totalAmount),
              formatAmountWithCurrency(this.submission.relatedOrder.amountLeft)
            ]
          );
          this.promptDialog.show = true;
          return false;
        }
      }

      let netAndVat =
        parseFloat(this.submission.netAmount) +
        parseFloat(this.submission.vatAmount);
      netAndVat = parseFloat(netAndVat).toFixed(2);

      // validate submission before save
      if (this.isVatExempt && parseFloat(this.submission.vatAmount) > 0) {
        this.promptDialog.message = this.$t('common.vatExempt');
        this.promptDialog.show = true;
        return false;
      }

      if (
        !this.isVatExempt &&
        parseFloat(this.submission.vatAmount) === 0 &&
        parseFloat(this.submission.netAmount) > 0
      ) {
        this.promptDialog.message = this.$t('common.vatCannotBeZero');
        this.promptDialog.show = true;
        this.currentStepIndex = 1;
        return;
      }

      if (this.submission.vatAmount > 0) {
        const netVatSum =
          this.submission.netAmount * parseFloat(this.vendorVat);

        let vatSub = parseFloat(this.submission.vatAmount - netVatSum).toFixed(
          2
        );

        vatSub = parseFloat(vatSub);

        // validate that order vat and total amount are correct
        if (vatSub > 1 || vatSub < -1) {
          // vat fix relevat until 01.04.2025
          const currentDate = new Date();
          const month = currentDate.getMonth() + 1;
          const year = currentDate.getFullYear();
          // this vat fix is relevant until 01.04.2025
          if (month < 4 && year <= 2025) {
            const vendorVatFix = 0.17;
            const netVatSumFix =
              this.submission.netAmount * parseFloat(vendorVatFix);
            let vatSubFix = parseFloat(
              this.submission.vatAmount - netVatSumFix
            ).toFixed(2);

            vatSubFix = parseFloat(vatSubFix);

            if (vatSubFix > 1 || vatSubFix < -1) {
              this.promptDialog.message = this.$t('common.invalidCalcVat');
              this.promptDialog.show = true;
              return false;
            }
          } else {
            this.promptDialog.message = this.$t('common.invalidCalcVat');
            this.promptDialog.show = true;
            return false;
          }
        }
      }

      const sub =
        parseFloat(this.submission.totalAmount) - parseFloat(netAndVat);

      if (sub > 1 || sub < -1) {
        this.promptDialog.message = this.$t('common.invalidTotalAmountCalc');
        this.promptDialog.show = true;
        return false;
      }

      return true;
    },
    async createSubmissionDocuments(documents, submission) {
      if (!documents?.length) {
        return null;
      }

      const files = documents.map((item) => item.file);
      const submissionDocumentsFiles = await uploadFiles(files, this.accountId);

      const submissionDocuments = submissionDocumentsFiles.map(
        (file, index) => {
          return {
            submissionId: submission._id,
            accountId: this.accountId,
            fileId: file._id,
            type: documents[index].type
          };
        }
      );

      const submissionDocRes = await createOrderSubmissionDocument(
        submissionDocuments,
        {
          accountId: this.accountId
        }
      );

      return submissionDocRes;
    },
    onApprove() {
      this.submissionDecisionDialog.decisionType = DecisionType.APPROVE;
      this.submissionDecisionDialog.documentType = this.submission.documentType;
      this.submissionDecisionDialog.show = true;
    },
    onReject() {
      this.submissionDecisionDialog.decisionType = DecisionType.REJECT;
      this.submissionDecisionDialog.documentType = this.submission.documentType;
      this.submissionDecisionDialog.show = true;
    },
    onReturn() {
      this.submissionDecisionDialog.decisionType = DecisionType.RETURN;
      this.submissionDecisionDialog.documentType = this.submission.documentType;
      this.submissionDecisionDialog.show = true;
    },
    async onShowHistory() {
      try {
        const query = {
          submissionId: this.submission._id
        };

        if (
          this.userIsAuthorizer ||
          this.userIsPurchaser ||
          this.userIsObserver
        ) {
          query.customerAccountId = this.accountId;
        } else {
          query.accountId = this.accountId;
        }

        this.loadingSubmissionHistory = true;

        const { data } = await getOrderSubmissionHistory(query);

        this.submissionHistoryDialog.items = data;
        this.submissionHistoryDialog.show = true;
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.loadingSubmissionHistory = false;
      }
    },
    async onSubmitSubmissionDecision(decisionType, form) {
      try {
        this.submissionDecisionDialog.loading = true;

        await createOrderSubmissionDecision({
          orderSubmissionId: this.submission._id,
          accountId: this.accountId,
          decision: this.getSubmissionStatusFromDecisionType(decisionType),
          returnType: decisionType === DecisionType.RETURN ? form.type : '',
          rejectType:
            decisionType === DecisionType.REJECT ? form.type : undefined,
          note: form.note || ''
        });

        this.submissionDecisionDialog.show = false;

        switch (decisionType) {
          case DecisionType.APPROVE:
            this.showSuccessToastMessage(
              this.$t('customer.vendorSubmissions.submissionApprovedSuccess')
            );

            this.submission = null;
            this.tab = 0;
            await this.loadSubmission();
            return;

          case DecisionType.REJECT:
            this.showSuccessToastMessage(
              this.$t('customer.vendorSubmissions.submissionRejectedSuccess')
            );
            break;
          case DecisionType.RETURN:
            this.showSuccessToastMessage(
              this.$t('customer.vendorSubmissions.submissionReturnedSuccess')
            );
            break;
        }

        this.$router.replace({
          name: 'customer-submissions'
        });
      } finally {
        this.submissionDecisionDialog.loading = false;
      }
    },
    getSubmissionStatusFromDecisionType(decisionType) {
      switch (decisionType) {
        case DecisionType.APPROVE:
          if (
            this.submission.status === OrderSubmissionStatus.PENDING_AUTHORIZER
          ) {
            return OrderSubmissionStatus.PENDING_PURCHASER;
          } else {
            return OrderSubmissionStatus.APPROVED;
          }

        case DecisionType.RETURN:
          return OrderSubmissionStatus.RETURNED;
        case DecisionType.REJECT:
          return OrderSubmissionStatus.REJECTED;
      }
    },
    isCustomerRoute() {
      return this.$route.matched.some(({ name }) => name === 'customer');
    },
    isVendorHomeRoute() {
      return this.$route.matched.some(({ name }) => name === 'vendor-home');
    },
    onGrabLinkClicked() {
      this.showSuccessToastMessage(this.$t('common.grabLinkInfo'));
    },
    onShowFullOrder() {
      const { href } = this.$router.resolve({
        name: 'customer-order',
        params: {
          id: this.submission.relatedOrderId
        }
      });
      window.open(href, '_blank');
    },

    onCreateChildSubmissionClicked(action) {
      this.createSubmissionDialog.parentSubmission = structuredClone(
        this.submission
      );
      this.createSubmissionDialog.title = this.$t(
        'vendor.orderSubmissions.createSubmissionForSubmission',
        [
          this.$t(`vendor.submissionDocTypes.${action.id}`),
          this.submission.documentNumber
        ]
      );
      this.createSubmissionDialog.documentType = action.id;
      this.createSubmissionDialog.inputLabel = this.$t(
        `vendor.submissionDocTypes.${action.id}`
      );
      this.createSubmissionDialog.show = true;
    },
    async uploadOrderSubmissionForParentSubmission(file) {
      try {
        this.createSubmissionDialog.loading = true;
        this.createSubmissionDialog.loadingTitle = this.$t(
          'vendor.orders.uploadingDocument'
        );

        const [documentFile] = await uploadFiles([file], this.accountId);

        const document = await createOrderSubmission({
          relatedOrderId:
            this.createSubmissionDialog.parentSubmission.relatedOrderId,
          parentSubmissionId: this.createSubmissionDialog.parentSubmission._id,
          accountId: this.accountId,
          documentType: this.createSubmissionDialog.documentType,
          customerAccountId:
            this.createSubmissionDialog.parentSubmission.customerAccountId,
          documentFileId: documentFile._id,
          vendorId: this.vendor._id,
          status: 'new'
        });

        this.createSubmissionDialog.loadingTitle = this.$t(
          'vendor.orders.processingDocument'
        );

        // after document has been created, we need to wait about 4-5 seconds to order to be processed
        // In the future it will be done via messaging queue or via websockets.
        setTimeout(() => {
          clearInterval(this.uploadDocTimer);
          // this.countSubmissions();
          this.handleProcessedDocument(document._id);
          this.createSubmissionDialog.show = false;
        }, DOCUMENT_PREDICTION_TIMEOUT);

        this.createSubmissionDialog.uploadProgress +=
          100 / (DOCUMENT_PREDICTION_TIMEOUT / 500);

        this.uploadDocTimer = setInterval(() => {
          this.createSubmissionDialog.uploadProgress +=
            100 / (DOCUMENT_PREDICTION_TIMEOUT / 500);
        }, 500);
      } catch (error) {
        this.createSubmissionDialog.show = false;
        this.createSubmissionDialog.loading = false;
        this.showErrorToastMessage(error.message);
      }
    },
    async handleProcessedDocument(docId) {
      this.$router.push({
        name: 'vendor-submission',
        params: {
          submissionId: docId,
          predicted: true
        }
      });
    },
    async onAddNote(note) {
      try {
        const noteItem = await createOrderSubmissionNote(
          {
            submissionId: this.submission._id,
            text: note
          },
          {
            accountId: this.accountId
          }
        );

        this.submission.notes.unshift(noteItem);
      } catch (error) {
        this.showErrorToastMessage(error.message);
      }
    },
    async onDeleteNote(item) {
      const index = this.submission.notes.indexOf(item);

      await deleteOrderSubmissionNote(item._id, {
        accountId: this.accountId
      });

      this.submission.notes.splice(index, 1);
    }
  },
  watch: {
    async currentSubmissionStepId() {
      this.$refs.observer.validate();
    },
    submissionId: {
      handler() {
        this.loadSubmission();
      }
    },
    'createSubmissionDialog.show': {
      handler(value, oldValue) {
        if (!value && oldValue) {
          this.createSubmissionDialog.parentSubmission = null;
          this.createSubmissionDialog.loadingTitle = null;
          this.createSubmissionDialog.loading = false;
          this.createSubmissionDialog.inputLabel = null;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep tbody {
  tr:hover {
    background-color: transparent !important;
  }
}

.container-height {
  max-height: calc(100vh - 360px) !important;
}
</style>
